import { gql } from '@apollo/client'

export type ContactRequestVars = {
  name: string
  email: string
  organization: string
  phone?: string
  useCase?: string
}

export type ContactRequestData = {
  contactRequest: string
}

export const CONTACT_REQUEST_MUTATION = gql`
  mutation ContactRequest(
    $name: String!
    $email: String!
    $organization: String!
    $phone: String
    $useCase: String
  ) {
    contactRequest(
      name: $name
      email: $email
      organization: $organization
      phone: $phone
      useCase: $useCase
    )
  }
`
