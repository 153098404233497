import * as React from 'react'

import { NavBar as BaseNavBar, ThemePaletteSwitch } from 'shared/components'

import NavLogo from './nav_logo'
import { themeColors } from '../mui-theme-config'

import type { NavBarProps as BaseNavBarProps } from 'shared/components'

type NavBarProps = Omit<BaseNavBarProps, 'bgcolor' | 'navLogo' | 'themePaletteSwitch'> & {
  disableThemePaletteSwitch?: boolean
  rootPath?: string
}

const NavBar = ({
  children,
  disableThemePaletteSwitch,
  rootPath,
  ...rest
}: NavBarProps) => (
  <BaseNavBar
    bgcolor={themeColors.dark}
    navLogo={<NavLogo href={rootPath} />}
    themePaletteSwitch={!disableThemePaletteSwitch && <ThemePaletteSwitch />}
    sx={{
      color: 'grey.200',
      '.MuiButton-text': { color: 'grey.200' },
      '.MuiIconButton-root': { color: 'grey.200' },
      '.MuiButton-outlined': { borderColor: 'grey.700' },
    }}
    {...rest}
  >
    {children}
  </BaseNavBar>
)

export default NavBar
