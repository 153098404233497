import { firstError, invalidAmountMsg } from 'shared/services'

import type { ExecutionResult, GraphQLErrorExtensions } from 'graphql'

export const translateGuitaError = (response: ExecutionResult) => {
  const error = firstError(response)

  switch (error?.message) {
  case 'BANK_DEPOSIT_IN_USE':
    return 'El depósito no se puede borrar porque tiene movimientos asociados.'
  case 'BULK_PURCHASE_CLOSED':
    return 'La orden está cerrada. Actualiza la página por favor.'
  case 'BULK_PURCHASE_IN_USE':
    return 'La orden no se puede borrar porque tiene movimientos asociados.'
  case 'DOCUMENT_NOT_FOUND':
    return 'Documento de identidad no encontrado.'
  case 'DOCUMENT_TYPE_ALREADY_EXISTS':
    return 'El usuario ya tiene un documento de identidad de este tipo.'
  case 'IDENTITY_NOT_EDITABLE':
    return 'Los datos de verificación solo se pueden actualizar mientras están en revisión.'
  case 'INVALID_ACTION':
    return 'Acción de modificación inválida.'
  case 'INVALID_AMOUNT':
    return invalidAmountMsg(error.extensions)
  case 'INVALID_AUTHORIZATION_CODE':
    return 'El código es incorrecto.'
  case 'INVALID_BANK_ACCOUNT_ID':
    return 'La cuenta bancaria no existe. Actualiza la página por favor.'
  case 'INVALID_BANK_DEPOSIT_ID':
    return 'El depósito no existe. Actualiza la página por favor.'
  case 'INVALID_BULK_PURCHASE_ID':
    return 'La orden no existe. Actualiza la página por favor.'
  case 'INVALID_CONFIG_ID':
    return 'Los configuración no existe. Actualiza la página por favor.'
  case 'INVALID_DOCUMENT_ID':
    return 'El documento de usuario no existe. Actualiza la página por favor.'
  case 'INVALID_FUND_ID':
    return 'El fondo no existe. Actualiza la página por favor.'
  case 'INVALID_LEGAL_IDENTITY_ID':
    return 'Los datos de identificación no existen. Actualiza la página por favor.'
  case 'INVALID_MARKET_ASSET_ID':
    return 'El activo ingresado no existe. Actualiza la página por favor.'
  case 'INVALID_NOTIFICATION_ID':
    return 'La notificación de usuario no existe. Actualiza la página por favor.'
  case 'INVALID_USER_ID':
    return 'El usuario no existe. Actualiza la página por favor.'
  case 'INVALID_USER_TYPE':
    return 'El tipo de usuario seleccionado no está habilitado en este dominio.'
  case 'SANCTIONED_ADDRESS':
    return 'La dirección blockchain está sancionada. Por seguridad esta operación fue bloqueada.'
  case 'USER_IS_CONFIRMED':
    return 'El mail del usuario ya está confirmado.'
  case 'USER_NOT_CONFIRMED':
    return 'El usuario debe confirmar su email antes de procesar esta solicitud.'
  case 'USER_NOT_VERIFIED':
    return 'El usuario debe verificar su identidad antes de procesar esta solicitud.'
  case 'VALIDATION_FAILED':
    return validationErrorMsg(error.extensions)
  case 'VERIFICATION_NOT_FOUND':
    return 'La verificación del usuario no existe. Actualiza la página por favor.'
  case 'VERIFICATION_STATE_INVALID':
    return 'El estado de verificación está desactualizado. Actualiza la página por favor.'
  default:
    return (error?.message) ? error.message : 'Ocurrió un error al procesar la solicitud.'
  }
}

const validationErrorMsg = (extensions: GraphQLErrorExtensions) => {
  const validationEntries = Object.entries(extensions)

  if (validationEntries.length === 0) {
    return 'Ocurrió un error al validar la solicitud.'
  }

  const errors = validationEntries.map(([field, message]) => `${field} ${message}`).join(', ')
  return `La validación falló: ${errors}`
}
