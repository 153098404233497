/* eslint-disable max-len */
import * as React from 'react'

import GuitaLogo from '../images/guita-logo.svg'

export const NavLogo = ({
  href,
}: { href?: string }) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <img
      src={GuitaLogo}
      alt='Logo Guita'
      height='16'
    />
  </a>
)

export default NavLogo
