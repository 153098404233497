import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { Dialog, ErrorDisplay, SeoHeaders } from 'shared/components'
import { setFormError } from 'shared/services'

import NavBar from '../components/nav_bar'
import { CONTACT_REQUEST_MUTATION } from '../queries/contact_form'
import { translateGuitaError } from '../services/error_messages'

import type { ContactRequestData, ContactRequestVars } from '../queries/contact_form'
import type { Theme } from '@mui/material'

type FormValues = ContactRequestVars

const initialValues: FormValues = {
  name: '',
  email: '',
  organization: '',
  phone: '',
  useCase: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  name: Yup.string()
    .required('Este campo es obligatorio'),
  email: Yup.string()
    .email('Tu email es inválido')
    .max(64, 'Tu email es muy largo')
    .required('Este campo es obligatorio'),
  organization: Yup.string()
    .required('Este campo es obligatorio'),
  phone: Yup.string()
    .matches(/^\+?[0-9]+( [0-9]+)*$/, 'Introduce un número de teléfono válido'),
  useCase: Yup.string()
    .max(500, 'El mensaje es muy largo'),
})

const ContactForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  submitted,
}: FormikProps<FormValues> & { submitted: boolean }) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ width: '100%' }}
  >
    <Field
      required
      name='name'
      type='text'
      label='Nombre'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      required
      name='organization'
      type='text'
      label='Empresa'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      required
      name='email'
      type='email'
      label='Email'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      name='phone'
      type='tel'
      label='Teléfono (opcional)'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      name='useCase'
      type='text'
      label='¿Qué buscas resolver? (opcional)'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <Button
      id='form-send'
      disabled={submitted || isSubmitting || !isValid}
      variant='contained'
      type='submit'
      size='large'
      fullWidth
      sx={{
        background: 'var(--color-mainTitleGradient)',
        fontWeight: 500,
        mt: 2,
      }}
    >
      Enviar
    </Button>
  </Form>
)

const ContactPage = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [contactRequest] =
    useMutation<ContactRequestData, ContactRequestVars>(CONTACT_REQUEST_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await contactRequest({ variables: values })
    const ok = response.data?.contactRequest

    if (ok) {
      setSubmitted(true)
      openDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <SeoHeaders title='Formulario de contacto' />
      <NavBar maxWidth='lg' />
      <Container
        maxWidth='lg'
        sx={{
          minHeight: 'calc(100vh - 56px)',
          flexGrow: 1,
          display: 'flex',
          py: 4,
        }}
      >
        <Grid
          container
          textAlign={useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? 'left' : 'center'}
          columnSpacing={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            md={6}
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            gap={3}
          >
            <Typography
              variant='h3'
              component='h1'
              fontWeight={500}
              textAlign='center'
            >
              Empieza tu nueva plataforma fintech en menos de 7 días
            </Typography>
            <Typography
              textAlign='center'
              variant='h5'
              component='h2'
              fontWeight={300}
            >
              Conversemos 30 minutos y te guiaremos paso&nbsp;a&nbsp;paso.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <ContactForm
                  submitted={submitted}
                  {...props}
                />
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth='xs'
        title='¡Tu Guita te espera!'
        contentText={'Pronto nos pondremos en contacto.'}
      />
    </React.Fragment>
  )
}

export default ContactPage
